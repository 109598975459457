import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Romain = () => {
  return (
    <Layout>
      <Head title="Romain Joveneau" />
      <h3>Romain Joveneau</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Montreal / Paris
      </h4>
      <p>
        <OutboundLink href="https://ello.co/rjov">Ello</OutboundLink>
      </p>
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/15291059/ello-optimized-0c42df2d.jpg"
        alt="Romain Joveneau art"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/15291060/ello-optimized-4e4a1201.jpg"
        alt="Romain Joveneau art"
      />
      <img
        src="https://assets0.ello.co/uploads/asset/attachment/15103311/ello-optimized-e931f7e0.jpg"
        alt="Romain Joveneau art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/15291061/ello-optimized-ae96b381.jpg"
        alt="Romain Joveneau art"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/14395396/ello-optimized-8802f9df.jpg"
        alt="Romain Joveneau art"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/15361989/ello-optimized-0489905b.jpg"
        alt="Romain Joveneau art"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/10118578/ello-optimized-fecb1a80.jpg"
        alt="Romain Joveneau art"
      />
      <img
        src="https://assets1.ello.co/uploads/asset/attachment/14755800/ello-optimized-f0f7e6e0.jpg"
        alt="Romain Joveneau art"
      />
      <img
        src="https://assets2.ello.co/uploads/asset/attachment/14755801/ello-optimized-4520849e.jpg"
        alt="Romain Joveneau art"
      />
    </Layout>
  );
};

export default Romain;
